import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FiChevronDown, FiChevronUp, FiMenu, FiX } from "react-icons/fi"
import Button from "./UI/Button"
import { getImageBySize, localePath } from "../utils"

export default function Menu({ locale, landing, type }) {
  const [active, setActive] = useState(false)
  const [hover, setHover] = useState(false)
  const [langActive, setLangActive] = useState(false)
  const data = useStaticQuery(graphql`
    {
      menu_fr: strapiMenu(locale: { eq: "fr" }) {
        MenuItem {
          label
          url
          products {
            id
            path
            name
            locale
            photos {
              ext
              url
              formats {
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
                thumbnail {
                  url
                }
              }
            }
          }
          pages {
            cover {
              ext
              url
            }
            id
            path
            title
            locale
            updatedAt
          }
        }
        MenuItemBtc {
          label
          url
          products {
            name
            path
            locale
            photos {
              ext
              url
              formats {
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
                thumbnail {
                  url
                }
              }
            }
          }
          pages {
            id
            cover {
              ext
              url
            }
            path
            title
            locale
            updatedAt
          }
        }
        Switch {
          label
          url
        }
      }
      menu_en: strapiMenu(locale: { eq: "en" }) {
        MenuItem {
          label
          url
          products {
            id
            path
            name
            locale
            photos {
              ext
              url
              formats {
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
                thumbnail {
                  url
                }
              }
            }
          }
          pages {
            cover {
              ext
              url
            }
            id
            path
            title
            locale
            updatedAt
          }
        }
        MenuItemBtc {
          label
          url
          products {
            name
            path
            locale
            photos {
              ext
              url
              formats {
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
                thumbnail {
                  url
                }
              }
            }
          }
          pages {
            id
            cover {
              ext
              url
            }
            path
            title
            locale
            updatedAt
          }
        }
        Switch {
          label
          url
        }
      }
      variable_fr: strapiVariable(locale: { eq: "fr" }) {
        email
        phone
        linkedin
        instagram
        facebook
        youtube
        pinterest
        tiktok
        phoneBtc
      }
      variable_en: strapiVariable(locale: { eq: "en" }) {
        email
        phone
        linkedin
        instagram
        facebook
        youtube
        pinterest
        tiktok
        phoneBtc
      }
    }
  `)

  const { menu_fr, menu_en, variable_fr, variable_en } = data

  const [menu, variable] =
    locale === "fr" ? [menu_fr, variable_fr] : [menu_en, variable_en]

  const switchStyle = {
    Entreprise: type !== "BTC" ? "text-blue font-bold" : "text-black",
    Particulier: type === "BTC" ? "text-pink font-bold" : "text-black",
  }

  return (
    <>
      <div
        id="menu-mobile"
        className="sticky top-0 w-screen lg:hidden px-6 py-1 bg-white shadow-md z-20"
      >
        <div className="h-12 w-full flex justify-between items-center">
          {!active && (
            <FiMenu className="h-6 w-6" onClick={() => setActive(true)} />
          )}
          {active && (
            <FiX className="h-6 w-6" onClick={() => setActive(false)} />
          )}
          <a
            href={locale === "fr" ? "/" : "/en"}
            className="w-28 h-8 mr-6 hover:opacity-7"
          >
            <img src="/logo-noir.png" className="h-full" alt="Wizito" />
          </a>
        </div>
        <div
          onClick={() => setActive(false)}
          className="w-full flex flex-col justify-between items-center transition-all duration-300 overflow-hidden text-base"
          style={{ maxHeight: active ? 600 : 0 }}
        >
          {landing !== "landing" &&
            type === "BTB" &&
            menu.MenuItem.map(({ label, url }) => (
              <a key={url} href={url} className="my-2 hover:text-blue-dark">
                {label}
              </a>
            ))}

          {landing !== "landing" &&
            type === "BTC" &&
            menu.MenuItemBtc.map(({ label, url }) => (
              <a key={url} href={url} className="my-2 hover:text-blue-dark">
                {label}
              </a>
            ))}
          {type !== "BTC" ? (
            <div
              className="h-8 mx-16 mt-1 relative text-med text-black"
              style={{ fontSize: "0.95rem", minWidth: "41rem" }}
            >
              {variable.phone}
            </div>
          ) : (
            <div
              className="h-8 mx-16 mt-1 relative text-med text-black"
              style={{ fontSize: "0.95rem", minWidth: "41rem" }}
            >
              {variable.phoneBtc}
            </div>
          )}
          <div className="flex items-center">
            {landing !== "landing" &&
              menu.Switch.map(({ label, url }, index) => (
                <>
                  <a
                    href={url}
                    className={`my-2 hover:font-bold ${switchStyle[label]}`}
                  >
                    {label}
                  </a>

                  {index !== 1 && <span className="mx-2">/</span>}
                </>
              ))}
          </div>

          <div className="flex my-2">
            <a
              href="/"
              className="block h-5 w-8 mx-2 overflow-hidden bg-cover bg-center rounded shadow"
              style={{
                backgroundImage: `url(https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg)`,
              }}
            />
            <a
              href="/en"
              className="block h-5 w-8 mx-2 overflow-hidden bg-cover bg-center rounded shadow"
              style={{
                backgroundImage: `url(https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg)`,
              }}
            />
          </div>
          <Button className="my-2" href="#form">
            {locale === "fr" ? "Nous contacter" : "Contact us"}
          </Button>
        </div>
      </div>
      <div
        id="menu"
        className="hidden lg:block sticky top-0 w-full h-28 px-8 py-4 bg-white shadow-md z-20"
      >
        <div className="flex justify-end items-center cursor-pointer -mb-4 mx-8">
          {landing !== "landing" &&
            menu.Switch.map(({ label, url }, index) => (
              <div
                className="relative text-sm text-black"
                key={url}
                onMouseEnter={() => setHover(url)}
                onMouseLeave={() => setHover(false)}
              >
                <>
                  <a
                    href={url}
                    className={`m-2 hover:font-bold ${switchStyle[label]}`}
                  >
                    {label}
                  </a>

                  {index !== 1 && <span>/</span>}
                </>
              </div>
            ))}
          <div
            className="h-4 w-6 overflow-hidden bg-cover bg-center rounded shadow"
            onClick={() => setLangActive(!langActive)}
            style={{
              backgroundImage: `url(${
                locale === "fr"
                  ? "https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg"
                  : "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
              })`,
            }}
          />
          {langActive ? (
            <FiChevronUp
              className="ml-1 opacity-50"
              onClick={() => setLangActive(!langActive)}
            />
          ) : (
            <FiChevronDown
              className="ml-1 opacity-50"
              onClick={() => setLangActive(!langActive)}
            />
          )}
          {langActive && (
            <div className="absolute mt-12 mr-6">
              <a
                href={locale === "fr" ? "en" : "/"}
                className="block h-4 w-6 overflow-hidden bg-cover bg-center rounded shadow"
                style={{
                  backgroundImage: `url(${
                    locale !== "fr"
                      ? "https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg"
                      : "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
                  })`,
                }}
              />
            </div>
          )}
        </div>

        <div className="h-full w-full flex justify-between items-end mx-auto">
          <a
            href={locale === "fr" ? "/" : "/en"}
            className="w-28 h-16 mr-6 hover:opacity-75"
          >
            <img src="/logo-noir.png" className="h-full" alt="Wizito" />
          </a>
          {type === "BTB" || !type ? (
            <div
              className="flex justify-center mx-8"
              style={{ fontSize: "0.95rem", minWidth: "41rem" }}
            >
              {menu.MenuItem.map(({ label, url, products, pages }) => (
                <div
                  className="relative text-med text-black"
                  key={url}
                  onMouseEnter={() => setHover(url)}
                  onMouseLeave={() => setHover(false)}
                >
                  <a href={url} className="mx-2 hover:font-medium">
                    {label}
                  </a>
                  {hover === url && (products.length > 0 || pages.length > 0) && (
                    <div
                      className="absolute pt-5 transform"
                      style={{
                        left: "50%",
                        "--transform-translate-x": "-50%",
                        width: "19rem",
                      }}
                    >
                      <div className="relative bg-white shadow-lg p-2 rounded grid justify-center items-start grid-cols-2 mt-0 pb-12">
                        <div
                          className="absolute top-0 bg-white w-10 h-10 transform left-0 -translate-x-1/2 rotate-45"
                          style={{ marginLeft: "50%" }}
                        />
                        {products.map(product => (
                          <a
                            key={product.name}
                            href={localePath(product)}
                            className="w-32 m-2"
                          >
                            <div
                              className="bg-cover bg-center w-full h-20 rounded mb-1 opacity-100 hover:opacity-75 z-20 relative"
                              style={{
                                backgroundImage: `url('${getImageBySize(
                                  product.photos[0],
                                  "thumbnail"
                                )}')`,
                              }}
                            />
                            <div className="leading-none text-center text-xs hover:text-blue-dark">
                              {product.name}
                            </div>
                          </a>
                        ))}
                        {pages.map(page => (
                          <a
                            key={page.name}
                            href={localePath(page)}
                            className="w-32 m-2"
                          >
                            <div
                              className="bg-cover bg-center w-full h-20 rounded mb-1 opacity-100 hover:opacity-75 z-20 relative"
                              style={{
                                backgroundImage: `url('${getImageBySize(
                                  page.cover[0].url,
                                  "thumbnail"
                                )}')`,
                              }}
                            />
                            <div className="leading-none text-center text-xs hover:text-blue-dark">
                              {page.title}
                            </div>
                          </a>
                        ))}
                      </div>
                      <Button
                        variant="small"
                        className="mx-auto -mt-10"
                        href={url}
                      >
                        {locale === "fr" ? "Tout afficher" : "Show all"}
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div
              className="flex justify-evenly"
              style={{ fontSize: "0.95rem", minWidth: "41rem" }}
            >
              {menu.MenuItemBtc.map(({ label, url, products, pages }) => (
                <div
                  className="relative text-med text-black"
                  key={url}
                  onMouseEnter={() => setHover(url)}
                  onMouseLeave={() => setHover(false)}
                >
                  <a href={url} className="hover:font-medium">
                    {label}
                  </a>
                  {hover === url && (products.length > 0 || pages.length > 0) && (
                    <div
                      className="absolute transform"
                      style={{
                        left: "50%",
                        "--transform-translate-x": "-50%",
                        width: "19rem",
                      }}
                    >
                      <div className="relative bg-white shadow-lg p-2 rounded grid justify-center items-start grid-cols-2 -mt-0 pb-12">
                        <div
                          className="absolute top-0 bg-white w-10 h-10 transform left-0 -translate-x-1/2 rotate-45"
                          style={{ marginLeft: "50%" }}
                        />
                        {products.map(product => (
                          <a
                            key={product.name}
                            href={localePath(product)}
                            className="w-32 m-2"
                          >
                            <div
                              className="bg-cover bg-center w-full h-20 rounded mb-1 opacity-100 hover:opacity-75 z-20 relative"
                              style={{
                                backgroundImage: `url('${getImageBySize(
                                  product.photos,
                                  "thumbnail"
                                )}')`,
                              }}
                            />
                            <div className="leading-none text-center text-xs hover:text-blue-dark">
                              {product.name}
                            </div>
                          </a>
                        ))}
                        {pages.map(page => (
                          <a
                            key={page.name}
                            href={localePath(page)}
                            className="w-32 m-2"
                          >
                            <div
                              className="bg-cover bg-center w-full h-20 rounded mb-1 opacity-100 hover:opacity-75 z-20 relative"
                              style={{
                                backgroundImage: `url('${getImageBySize(
                                  page.cover[0],
                                  "thumbnail"
                                )}')`,
                              }}
                            />
                            <div className="leading-none text-center text-xs hover:text-blue-dark">
                              {page.title}
                            </div>
                          </a>
                        ))}
                      </div>
                      <Button
                        variant="small"
                        className="mx-auto -mt-10"
                        href={url}
                      >
                        {locale === "fr" ? "Tout afficher" : "Show all"}
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {type !== "BTC" ? (
          <div
            className="flex justify-end mx-12 mt-1 relative text-med text-black"
            style={{ fontSize: "0.95rem", minWidth: "41rem", zIndex: -1 }}
          >
            {variable.phone}
          </div>
        ) : (
          <div
            className="flex justify-end mx-16 mt-1 relative text-med text-black "
            style={{ fontSize: "0.95rem", minWidth: "41rem", zIndex: -1 }}
          >
            {variable.phoneBtc}
          </div>
        )}
      </div>
      {hover && (
        <div
          className="fixed -top-0 -bottom-0 left-0 right-0 bg-blue-dark opacity-25"
          style={{ zIndex: 2 }}
        />
      )}
    </>
  )
}
