import React from "react"

export default function Title({ children, style, className, id, level }) {
  const sizes = [
    "text-4xl lg:text-5xl",
    "text-xl lg:text-4xl",
    "text-xl lg:text-2xl",
  ]

  const Hn = props =>
    React.createElement(`h${level || 2}`, props, props.children)

  return (
    <Hn
      id={id}
      style={style}
      className={` text-black mt-3 mb-3 relative leading-none ${className} ${
        sizes[level - 1]
      }`}
    >
      {children}
    </Hn>
  )
}
