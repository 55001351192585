import React, { useState } from "react"
import Button from "./Button"

export default function OptionsTag({
  layout,
  options,
  isSelected,
  handleClick,
}) {
  return (
    <>
      {layout === "none" && (
        <div className="w-full flex flex-col lg:flex-row justify-center">
          {options.map((t, index) => (
            <div className="lg:mx-2 mx-8 flex justify-center">
              <Button
                id={index}
                variant={"default2"}
                onClick={() => handleClick(t, index)}
                className={`button w-full ${
                  isSelected === index ? "selected" : "default"
                }`}
                faq={true}
              >
                {t}
              </Button>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
