import React, { useState } from "react"
import { useTimeoutFn } from "react-use"
import { getImageBySize } from "../../utils"
import { FaCalendar } from "react-icons/fa"

export default function BlogCard({
  children,
  header,
  className,
  href,
  title,
  description,
  date,
  author,
}) {
  return (
    <a
      href={href}
      target={href && href.match("http") ? "_blank" : ""}
      rel={href && href.match("http") ? "noreferrer noopener" : ""}
      className={`w-1/2+m overflow-hidden cursor-pointer ${className}`}
    >
      <div className="w-full overflow-hidden flex flex-col justify-center items-center text-left mt-6">
        {header}
        <div
          className={`w-full flex flex-col justify-center text-lg lg:text-lg leading-8 px-1`}
        >
          {title && (
            <strong className="text-base lg:text-2xl leading-none my-2">
              {title}
            </strong>
          )}
          {description && (
            <div className="w-full font-light leading-none lg:leading-tight text-sm lg:mt-2">
              {description}
            </div>
          )}
          <div className="w-full flex lg:mt-8 invisible lg:visible">
            {author.map((auth, i) => (
              <div key={i} className="w-full flex justify-start items-center">
                <div
                  className="h-16 w-20 lg:w-20 lg:h-16 bg-contain bg-no-repeat bg-center"
                  style={{
                    backgroundImage: `url(${getImageBySize(auth, "small")})`,
                  }}
                ></div>
                <span className="text-left text-xs mx-1">{auth.name}</span>
                <FaCalendar className="h-3 ml-2 my-auto" fill="#5AA1D8" />
                <span className="text-left text-xs mr-1">{date}</span>
              </div>
            ))}
          </div>
          {children}
        </div>
      </div>
    </a>
  )
}
