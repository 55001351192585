import React from "react"
import { Helmet } from "react-helmet"
import { localePath } from "../../utils"
import CookieBanner from "../CookieBanner"
import Menu from "../Menu"
import Footer from "../Footer"
import Form from "../Form"

export default function Layout({
  children,
  title,
  description,
  path,
  linkedData,
  locale,
  landing,
  type,
}) {
  return (
    <div>
      <CookieBanner locale={locale} />
      <div
        className="h-screen overflow-y-auto overflow-x-hidden"
        style={{
          perspective: "1px",
          perspectiveOriginX: "100%",
          transformStyle: "preserve-3d",
          scrollBehavior: "smooth",
        }}
      >
        <div
          className="min-h-screen flex flex-col w-screen"
          style={{ transformStyle: "preserve-3d" }}
        >
          <Helmet htmlAttributes={{ lang: locale }}>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta httpEquiv="Content-Language" content={locale} />
            <meta name="description" content={description} />
            {(landing === "landing-integrated" || landing === "landing") && (
              <meta name="robots" content="noindex,nofollow" />
            )}
            <link rel="icon" type="image/png" href="/favicon.ico" />
            <meta
              property="og:logo"
              content="https://www.wizito.com/logo-noir.png"
            />
            <meta
              name="facebook-domain-verification"
              content="mr5wdyuft5jkcmu6eb2hl1ql64u5xi"
            />
            <script type="text/javascript" id="hs-script-loader" async defer src="https://js.hs-scripts.com/8689458.js" />
            <script type="text/javascript" id="hs-script-loader" async defer src="https://js.hs-scripts.com/25428855.js" />
            {path === "home" ? (
              <link rel="canonical" href={`https://www.wizito.com`} />
            ) : (
              <link
                rel="canonical"
                href={`https://www.wizito.com${localePath({ path, locale })}`}
              />
            )}
            <script type="application/ld+json">
              {JSON.stringify([
                {
                  "@context": "https://schema.org/",
                  "@type": "LocalBusiness",
                  name: "Wizito",
                  image: "https://www.wizito.com/logo-noir.png",
                  logo: "https://www.wizito.com/logo-noir.png",
                  url: "https://www.wizito.com",
                  email: "sales@wizito.com",
                  telephone: "0184606164",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "218 Avenue du Président Wilson",
                    addressLocality: "Saint-Denis",
                    postalCode: "93200",
                    addressCountry: "FR",
                  },
                  aggregateRating: {
                    "@type": "AggregateRating",
                    bestRating: "5",
                    worstRating: "1",
                    ratingValue: "4.7",
                    ratingCount: "93",
                    reviewCount: "93",
                  },
                },
                {
                  "@context": "https://schema.org",
                  "@type": "WebSite",
                  name: "Wizito",
                  url: "https://www.wizito.com",
                },
                ...(linkedData || []),
              ])}
            </script>
          </Helmet>
          {landing !== "landing" && (
            <Menu locale={locale} landing={landing} type={type} />
          )}
          <div className="max-w-screen-xl w-full px-4 lg:px-0 flex-grow mx-auto z-0">
            {children}
            <Form locale={locale} />
          </div>
          {landing !== "landing" && <Footer locale={locale} type={type} />}
        </div>
      </div>
    </div>
  )
}
