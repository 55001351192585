import React, { useState } from "react"
import Help from "./Help"
import Title from "./Title"
import OptionsTag from "./OptionsTag"

export default function HelpWall({ faqContent, helps, id, title, layout }) {
  const options = [
    "Location",
    "Réservations",
    "Informations techniques",
    "Livraison et retour",
    "Impressions",
  ]

  const [option, setOption] = useState(options[0])
  const [isSelected, setIsSelected] = useState(0)

  const handleClick = (e, index) => {
    setIsSelected(index)
    setOption(e)
  }

  if (!helps.length) return null

  return (
    <>
      <OptionsTag
        layout={layout}
        options={options}
        isSelected={isSelected}
        handleClick={handleClick}
      />
      {layout === "text_left" ? (
        <div id={id} className="relative lg:flex lg:justify-center my-5 m-4">
          {faqContent && layout !== "none" && (
            <dl className="lg:w-1/2 text-base pt-20 m-3">
              {title && (
                <Title
                  level={title.level}
                  className={`text-left`}
                  style={{ marginTop: 0 }}
                >
                  {title.content}
                </Title>
              )}
              {faqContent.text.data.text}
            </dl>
          )}
          <dl
            className={
              faqContent
                ? `lg:w-1/2 w-full flex flex-col items-center justify-start pt-10 lg:pt-20 text-base`
                : `w-full flex flex-col items-center justify-start pt-10 lg:pt-20 text-base`
            }
          >
            {helps.map((help, index) => (
              <Help key={help.id} help={help} index={index} />
            ))}
          </dl>
        </div>
      ) : (
        <div
          id={id}
          className="relative lg:flex lg:flex-row lg:justify-center flex flex-col-reverse my-5 m-4"
        >
          <dl
            className={
              faqContent
                ? `lg:w-1/2 w-full flex flex-col items-center justify-start pt-10 lg:pt-20 text-base`
                : `w-full flex flex-col items-center justify-start pt-10 lg:pt-20 text-base`
            }
          >
            {helps.map(
              (help, index) =>
                option === help.label && (
                  <Help key={help.id} help={help} index={index} />
                )
            )}
          </dl>
          {faqContent && layout !== "none" && (
            <dl className="lg:w-1/2 text-base pt-20 m-3">
              {title && (
                <Title
                  level={title.level}
                  className={`text-left`}
                  style={{ marginTop: 0 }}
                >
                  {title.content}
                </Title>
              )}
              {faqContent.text.data.text}
            </dl>
          )}
        </div>
      )}
    </>
  )
}
