import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Title from "./Title"

function formatBigNumber(number) {
  return Intl.NumberFormat("fr-FR", { notation: "compact" }).format(number)
}

export default function Carousel({ content, locale, title, id, images, type }) {
  const data = useStaticQuery(graphql`
    {
      variable_fr: strapiVariable(locale: { eq: "fr" }) {
        clientsCount
        eventsCount
        smilesCount
      }
    }
  `)
  const { clientsCount, eventsCount, smilesCount } = data.variable_fr

  return (
    <div className="flex flex-col h-screen/test inset-x-0 m-auto">
      <div className="relative w-full h-full bg-cover bg-center">{images}</div>
      <div
        className="top-0 absolute lg:block h-full w-screen opacity-0
          bg-black"
      />
      {/*<Title
        className="bottom-0 text-white leading-none text-4xl lg:text-6xl lg:ml-32 ml-10 mb-40 lg:w-1/3+m"
        level={title.level}
        style={{ position: "absolute" }}
      >
        {title.content}
      </Title>*/}
      {type !== "BTC" && (
        <div className="flex justify-evenly items-center bg-white p-2 lg:p-4 text-center overflow-hidden z-20">
          <div className="m-1 w-1/3+m flex flex-col items-center">
            <div className="text-2xl font-extrabold lg:text-4xl text-black">
              {formatBigNumber(clientsCount)}
            </div>
            <div className="leading-none text-base lg:text-2xl">
              {locale === "fr" ? "clients B2B" : "B2B clients"}
            </div>
          </div>
          <div className="m-1 w-1/3+m flex flex-col items-center">
            <div className="text-2xl font-extrabold lg:text-4xl text-black">
              {formatBigNumber(eventsCount)}
            </div>
            <div className="leading-none text-base lg:text-2xl">
              {locale === "fr" ? "événements réalisés" : "events made"}
            </div>
          </div>
          <div className="m-1 w-1/3+m flex flex-col items-center">
            <div className="text-2xl font-extrabold lg:text-4xl text-black">
              {formatBigNumber(smilesCount)}
            </div>
            <div className="leading-none text-base lg:text-2xl">
              {locale === "fr" ? "sourires imprimés" : "printed smiles"}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
