import React, { useState } from "react"
import { useTimeoutFn } from "react-use"

export default function Card({
  children,
  header,
  className,
  href,
  layout,
  title,
  description,
}) {
  const pattern = {
    mini:
      "absolute flex-wrap text-white font-semibold w-32 lg:w-64 rounded-3xl text-center items-center",
    maxi: "relative w-full text-black",
  }

  const [load, setLoad] = useState(false)
  useTimeoutFn(() => setLoad(true), Math.random() * 1000 + 750)

  return (
    <a
      href={href}
      target={href && href.match("http") ? "_blank" : ""}
      rel={href && href.match("http") ? "noreferrer noopener" : ""}
      className={`w-1/2+sm lg:w-1/5 m-2 lg:m-10 overflow-hidden cursor-pointer ${className}`}
    >
      <div className="w-full overflow-hidden rounded-3xl flex flex-col justify-center items-center text-left">
        {load && header}
        <div
          className={`${pattern[layout]} flex flex-col text-lg lg:text-lg leading-8 px-1`}
        >
          {title && (
            <strong
              className="text-base lg:text-2xl leading-none my-2"
              style={
                layout === "mini"
                  ? {
                      textShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                    }
                  : { textShadow: "none" }
              }
            >
              {title}
            </strong>
          )}
          {description && layout !== "mini" && (
            <div className="font-light leading-none lg:leading-tight text-sm">
              {description}
            </div>
          )}
          {children}
        </div>
      </div>
    </a>
  )
}
